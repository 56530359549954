
body {
  margin: 0;
  font-family: 'DIN Next LT Pro Condensed', -apple-system, 'DIN Next LT Pro Condensed', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight:300;
  font-style: normal;
  font-size: '18px';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DIN Next LT Pro Condensed', -apple-system, 'DIN Next LT Pro Condensed', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight:300;
  font-style: normal;
  font-size: '18px';
}

.react-tel-input .country-list .country-name{
  color: black;
}

.react-tel-input {
  margin: 20px 0;
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
  margin: auto;
}

.custom-stepper.MuiMobileStepper-root{
  background: transparent !important;
}

.custom-stepper .MuiMobileStepper-dotActive {
  background-color: #ffffff;
}

.atcb{
  margin:auto;
}

.custom-styles .ReactInputVerificationCode__container{
  margin: auto;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: white;
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #ebebeb;
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #046cde;
}

.MuiMobileStepper-dots .MuiMobileStepper-dot{
  background-color: grey;
}

.MuiMobileStepper-dots .MuiMobileStepper-dot.MuiMobileStepper-dotActive{
  background-color: #046cde;
}

#custom-header .MuiFormLabel-root.Mui-focused {
  color: white;
}

/* mobile portrait */
@media screen and (max-width: 468px) {
  #custom-header {
      max-width: 95% !important;
  }

  #main-container{
    margin-bottom: 60px !important;
  }

  #main-header {
    margin-bottom: 40px !important;
  }
}
